<div class="input-date" #inputField>
  <mat-form-field class="date-range" [class.full-width]="fullWidth" appearance="standard">
    <mat-date-range-input
      [max]="maxDate"
      [min]="minDate"
      [rangePicker]="picker"
      [formGroup]="form"
    >
      <input
        readonly
        matStartDate
        [placeholder]="data?.dashboard.start_date"
        formControlName="start_date"
        (click)="openPicker()"
      />
      <input
        readonly
        matEndDate
        [placeholder]="data?.dashboard.end_date"
        formControlName="end_date"
        (dateChange)="onStartDateChange()"
        (click)="openPicker()"
      />

      <span class="mat-date-range-input-separator">–</span>
    </mat-date-range-input>

    <span
      class="close"
      *ngIf="form.value.start_date && form.value.end_date"
      (click)="form.reset(); onStartDateChange()"
    >
      x
    </span>
    <mat-datepicker-toggle
      matSuffix
      [for]="picker"
      (click)="openPicker()"
    ></mat-datepicker-toggle>
    <mat-date-range-picker
      #picker
      [panelClass]="'custom-overlay-panel'"
    ></mat-date-range-picker>
  </mat-form-field>
</div>
