<div class="sidebar">
  <span class="close-button" (click)="navItemClicked.emit()">
    <img src="./assets/close-x-gray.svg" />
  </span>
  <div class="sidebar__header" [class.expand]="sidebarActive">
    <a class="sidebar__header__logo" target="_blank" (click)="isbold = !isbold">
    <img src="./assets/pos-malaysia-logo-white.svg" />
    </a>
  </div>
  
  <mat-nav-list class="sidebar__nav-list">
    <span *ngFor="let item of navMenu;">
      <!-- menu without child -->
			<ng-container *ngIf="!item.children?.length; else menuChild">
        <span class="parent-only" [ngClass]="{'logout': item?.iconName == 'exit_to_app'}">
          <a
            mat-list-item
            (click)="navItemClicked.emit(); item?.iconName === 'exit_to_app' && logout()"
            class="mat-list-item"
            [class.collapse]="!sidebarActive"
            [class.expand]="sidebarActive"
            [routerLink]="item.routerLink"
            routerLinkActive="active-link"
            [routerLinkActiveOptions]="{ exact: true }"
            matLine
          >
            <span class="material-icons-outlined"> {{ item?.iconName }} </span>
            <span *ngIf="sidebarActive" class="label"> {{ item?.displayName }} </span>
          </a>
        </span>
			</ng-container>
      <!-- menu with child -->
			<ng-template #menuChild>
        <span class="parent-child" [hidden]="isMasterAccount ? '' : item.displayName === 'Billing'">
          <mat-accordion>
            <mat-expansion-panel [class.expand]="sidebarActive" [class.collapse]="!sidebarActive" [expanded]="sidebarActive">
              <mat-expansion-panel-header
                [class.active-link]="item.displayName === 'Shipments' ?
                  (firstPathUrl === 'shipment' ||
                  firstPathUrl === 'bulk-shipment' ||
                  firstPathUrl === 'my-shipment' ||
                  firstPathUrl === 'contact') :  item.displayName === 'Billing' ? (firstPathUrl === 'billing') : item.displayName === 'Integration' ? (firstPathUrl === 'integration' || firstPathUrl === 'my-store'): '' ">
                <span class="parent-nav w-100" [routerLink]="item.routerLink" (click)="navItemClicked.emit()" >
                  <mat-panel-title >
                  <span class="material-icons-outlined"> {{ item?.iconName }} </span>
                  <span *ngIf="sidebarActive" class="label"> {{ item?.displayName }} </span>
                  </mat-panel-title>
                </span>
              </mat-expansion-panel-header>
              <span *ngFor="let child of item.children" class="child-nav">
                <a
                  *ngIf="child && sidebarActive"
                  mat-list-item
                  (click)="navItemClicked.emit()"
                  class="sidebar__child-nav-list mat-list-item"
                  [routerLink]="child.routerLink"
                  [queryParams]="child?.queryParam"
                  routerLinkActive="active-link"
                  [routerLinkActiveOptions]="routerLinkActiveOptions"
                  matLine
                >
                  <span class="material-icons-outlined submenu-icon">
                    {{ child?.iconName }}
                  </span>
                  <span class="label">{{ child?.displayName }}</span>
                </a>
              </span>
            </mat-expansion-panel>
          </mat-accordion>
        </span>
			</ng-template>
    </span>
  </mat-nav-list>
</div>
  